import { PUSH_SUFFIX } from './beylaConstants';
import { generateMetricFeatures } from './generateStandaloneBeylaConfig';

export function generateAlloyBeylaConfig({
  token,
  hostedPromId,
  hpInstanceUrl,
  hostedTempoId,
  htInstanceUrl,
  includeAssertsAppO11y,
  includeOtelApplication,
  includeOtelProcess,
  includeOtelTraces,
}: {
  token: string;
  hostedPromId: number;
  hpInstanceUrl: string;
  hostedTempoId: number;
  htInstanceUrl: string;
  includeAssertsAppO11y: boolean;
  includeOtelApplication: boolean;
  includeOtelProcess: boolean;
  includeOtelTraces: boolean;
}) {
  const metricsFeatures = generateMetricFeatures({
    includeAssertsAppO11y,
    includeOtelApplication,
    includeOtelProcess,
  });

  const formattedFeatures = metricsFeatures
    .split(',')
    .map((feature) => `"${feature}"`)
    .join(',\n\t    ');

  return `helm repo add grafana https://grafana.github.io/helm-charts &&
  helm repo update &&
  helm upgrade --install --atomic --timeout 300s grafana-alloy grafana/alloy \\
    --namespace "default" --create-namespace --values - <<EOF
alloy:
  configMap:
    create: true
    content:  |
      beyla.ebpf "default" {
        attributes {
          kubernetes {
            enable = "true"
          }
          select {
            beyla_network_flow_bytes {
              include = [
                "k8s.src.owner.type",
                "k8s.dst.owner.type",
                "direction"
              ]
            }
          }
        }
        filter {
          network {
            k8s_dst_owner_name {
              not_match = "{kube*,*jaeger-agent*,*prometheus*,*promtail*,*grafana-agent*}"
            }
            k8s_src_owner_name {
              not_match = "{kube*,*jaeger-agent*,*prometheus*,*promtail*,*grafana-agent*}"
            }
          }
        }
        debug = true
        discovery {
          services {
            kubernetes {
              deployment_name = "."
            }       
          } 
          services {
            kubernetes {
              daemonset_name = "."
            }
          }
        }
        metrics {
          features = ${
            metricsFeatures !== ''
              ? `[
            ${formattedFeatures}
          ]`
              : '[]'
          }
          network {
            enable = true
          }     
        } ${
          includeOtelTraces
            ? `\n\toutput {
          traces = [otelcol.exporter.otlp.grafana_cloud_tempo.input]
        }`
            : ''
        }
      }
      prometheus.scrape "beyla" {
        targets      = beyla.ebpf.default.targets
        honor_labels = true
        forward_to   = [prometheus.remote_write.rw.receiver]
      }
      prometheus.remote_write "rw" {
        endpoint {
          url = ${hpInstanceUrl}${PUSH_SUFFIX}
          basic_auth {
            username = ${hostedPromId}
            password = ${token ?? '<Generate token to continue>'}
          }    
        } 
      } ${
        includeOtelTraces
          ? `
      otelcol.exporter.otlp "grafana_cloud_tempo" {
        client {
          endpoint = ${htInstanceUrl}
          auth     = otelcol.auth.basic.grafana_cloud_tempo.handler
        }
      }
      otelcol.auth.basic "grafana_cloud_tempo" {
        username = ${hostedTempoId}
        password = ${token ?? '<Generate token to continue>'}
      }`
          : ''
      }
  stabilityLevel: public-preview  
  securityContext:
    privileged: true
controller:
  hostPID: true
EOF`;
}
