import { config } from '@grafana/runtime';

import { isGrafanaAtLeast10 } from './misc';

export function isCSPAppEnabled(): boolean {
  return config.apps !== undefined && Object.hasOwn(config.apps, 'grafana-csp-app') && isGrafanaAtLeast10();
}

export function isCollectorAppEnabled(): boolean {
  return config.apps !== undefined && Boolean(config.apps['grafana-collector-app']);
}
